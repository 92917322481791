<template>
    <div :id="'event-'+item.id" :style="style" class="cell-event" @click="editEvent()">
      {{item.title || "no title"}}
    </div>
</template>

<script>
import moment from 'moment';
export default {
  props: ['item','day','hour','calendar','automaticOpen'],
  data(){
    return {
      offset: 3,
      style: {
        marginTop: 0,
        height: "25px",
        backgroundColor: "#007bff"
      }
    }
  },
  components: {

  },

  methods:{
    editEvent(){
      this.$emit('onEditEvent',{item: this.item,day: this.day,hour: this.hour});
    },
    addEventNextDay(){
      let newStartDT = moment(this.item.startDate).add(1,'day').startOf('day');
      this.calendar.addVirtualEvent({
        id: this.item.id,
        title: this.item.title,
        message: this.item.message,
        meetingLink: this.item.meetingLink,
        startDate: newStartDT.format('YYYY-MM-DD'),
        startTime: newStartDT.format('HH:mm'),
        endDate: this.item.endDate,
        endTime: this.item.endTime,
        bgColor: this.item.bgColor,
        eventType: this.item.eventType,
        guestEmail: this.item.guestEmail,
      });
    },
    getMaxHeight(){
      let minutes = 1440;
      let value = minutes / this.calendar.settings.slot * 100;
      let coverSlots = Math.round(minutes / this.calendar.settings.slot);
      let borderMargin = coverSlots * this.offset;
      return value+borderMargin;
    },
    getPrevHeight(startDT){
      let dayStartDT = moment(this.item.startDate).startOf('day');
      let prevDuration = moment.duration(startDT.diff(dayStartDT));
      let prevMinutes = prevDuration.asMinutes();
      let prevValue = prevMinutes / this.calendar.settings.slot * 100;
      let coverSlots = Math.round(prevMinutes / this.calendar.settings.slot);
      let borderMargin = coverSlots * this.offset;
      return prevValue+borderMargin;
    },
    getHeight(){
      let startDT = moment(this.item.startDate+' '+this.item.startTime)
      let endDT = moment(this.item.endDate+' '+this.item.endTime);
      let duration = moment.duration(endDT.diff(startDT));
      let minutes = duration.asMinutes();
      let value = minutes / this.calendar.settings.slot * 100;

      let coverSlots = Math.round(minutes / this.calendar.settings.slot);
      let borderMargin = coverSlots * this.offset;

      let eventHeight = value+borderMargin;
      let prevHeight = this.getPrevHeight(startDT);
      let maxHeight = this.getMaxHeight();
      let remainingHeight = maxHeight - prevHeight;

      //console.log(maxHeight,eventHeight,prevHeight,eventHeight-prevHeight)

      if(eventHeight > remainingHeight){
        this.addEventNextDay();
        return remainingHeight+"px";
      }

      return eventHeight+"px";
    },

    getMarginTop(){
      let startDT = moment(this.day.date+' '+this.hour.start);
      let endDT = moment(this.item.startDate+' '+this.item.startTime);
      let duration = moment.duration(endDT.diff(startDT));
      let minutes = duration.asMinutes();

      return (minutes / this.calendar.settings.slot * 100)+"px";
    },

    setEventPosition(){
      this.style.height = this.getHeight();
      this.style.marginTop = this.getMarginTop();
      this.style.backgroundColor = this.item.bgColor;
    },
  },

  mounted(){
    this.setEventPosition();
    if(this.automaticOpen && this.automaticOpen.id === this.item.id){
      this.editEvent();
    }
  }
};
</script>
