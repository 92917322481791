<template>
    <div id="crm-calendar">
      <Calendar/>
    </div>
</template>

<script>
import Calendar from './../../components/CalendarSlim';
export default {
    props: ['user'],
    components: {
      Calendar
    },
    data(){
        return {

        };
    },

    methods:{
      notifyParent(){
        let data = {active: "crm.calendar"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },
    },

    mounted(){
        this.notifyParent();
    },
};
</script>
