<template>
    <div class="calendar-table-container">
      <table class="calendar-table">
        <thead>
          <tr>
            <th class="col-gmt"></th>
            <th :class="getDayHeaderClass(day)" v-for="(day,key) in calendar.days" :key="key">
              <div class="day-header-container">
                <div class="day-header-text">
                  <div class="day-title">{{day.title}}</div>
                  <div class="day-subtitle">{{day.subtitle}}</div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(hour,hk) in hours">
            <template v-if="hk === 0">
              <tr :key="hk+'-empty'">
                <td>
                  <div class="current-ticker"></div>
                </td>
                <td v-for="(day,dk) in calendar.days" :key="dk+'-empty'" :class="getSlotClass(day,hour)">
                  <div class="events-container-empty">

                  </div>
                </td>
              </tr>
            </template>
            <tr :id="'h-row-'+hour.startTimeSlug" :ref="'h-row-'+hour.startTimeSlug" :key="hk">
              <td class="cell-gmt">
                <div class="gmt-h">
                  <BIconClock/> {{hour.start}}
                  <BIconCaretRightFill class="gmt-h-arrow"/>
                </div>
              </td>
              <td
                :id="'d-cell-'+day.date+'-h-'+hour.startTimeSlug"
                :ref="'d-cell-'+day.date+'-h-'+hour.startTimeSlug"
                :class="getSlotClass(day,hour)" v-for="(day,dk) in calendar.days"
                :key="dk"
                >

                <div
                  class="events-container"
                  @click.self="slotSelected(day, hour)"
                  >
                  <template v-for="(item,ek) in calendar.getEventsInSlot(day,hour)">
                      <div
                        class="event-draggable"
                        :id="'ev-drag-'+item.id"
                        :key="ek+'_ev_'+day.date+'_'+hour.startTimeSlug+'_'+hour.endTimeSlug"
                        >
                        <SlotCellEvent
                          :ref="item.id"
                          :id="item.id"
                          :item="item"
                          :day="day"
                          :hour="hour"
                          :calendar="calendar"
                          @onEditEvent="editEvent"
                          :automaticOpen="automaticOpen"
                        />
                      </div>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
</template>

<script>
import SlotCellEvent from './Slot.Cell.Event';
import moment from 'moment';
import {BIconClock,BIconCaretRightFill} from 'bootstrap-vue';
export default {
    props: ['calendar','automaticOpen'],
    components: {
      BIconClock,BIconCaretRightFill,SlotCellEvent
    },
    data(){
        return {
          hours: [],
          tickerInterval: null,
          draggingItem: null
        }
    },

    methods:{
      getDayHeaderClass(day){
        return 'col-day '+day.tags.join(' ');
      },
      getSlotClass(day,hour){
        let arr = ['cell-slot'];
        let status = this.calendar.getHourStatus(day,hour);
        if(status.isCurrent){
          arr.push('is-current');
        }
        if(status.isPast && !status.isCurrent){
          arr.push('is-past');
        }
        if(status.isFuture){
          arr.push('is-future');
        }
        return arr.concat(day.tags).join(' ');
      },

      positionTicker(activeSlot){
        let currentTicker = document.querySelector('.current-ticker');
        let remainingSpace = 43;
        let currentMinute = moment().format('mm');
        currentTicker.style.top = (activeSlot.offsetTop+remainingSpace+parseInt(currentMinute))+"px";
      },

      slotSelected(day, hour){
        this.$emit('onSlotSelected',{day,hour});
      },

      editEvent(data){
        this.$emit('onEditEvent',data);
      },

      updateSlotEvent(eventId){
        this.$refs[eventId][0].setEventPosition();
      },
    },

    beforeDestroy(){
      clearInterval(this.tickerInterval);
    },

    mounted(){
      this.hours = this.calendar.hours;

      setTimeout(() => {
        let activeHour = this.hours.find(o => o.isCurrent);
        let activeSlot = document.querySelector('tr#h-row-'+activeHour.startTimeSlug);
        document.querySelector('.calendar-body').scrollTop = activeSlot.previousElementSibling.offsetTop - 120;

        this.positionTicker(activeSlot);
        this.tickerInterval = setInterval(() => {
          this.positionTicker(activeSlot);
        },1000*60)
      },300);
    },
};
</script>
