import moment from 'moment';

export default class Calendar{

  constructor(settings, events){
    this.events = events || [];
    this.virtualEvents = [];
    this.settings = this.setSettings(settings);
    moment.updateLocale(this.settings.locale,{
      week: {
          dow: 1
      }
    });

    this.setViewRange(this.settings.viewRange);
    this.hours = this.enumerateHours(this.settings.slot);
    this.startDate = null;
    this.endDate = null;
  }

  getViewRangeNoDays(){
    switch(this.settings.viewRange){
      case "1-day":
        return 1;

      case "4-days":
        return 4;
    }

    return null;
  }

  setDayRange(startDate,endDate){
    this.startDate = startDate;
    if(!endDate && this.getViewRangeNoDays() > 0){
        if(this.getViewRangeNoDays() > 1){
          this.endDate = moment(this.startDate).add(this.getViewRangeNoDays()-1,'days').format('YYYY-MM-DD');
        }
        else{
          this.endDate = null;
        }
    }

    if(this.settings.viewRange === "week"){
      this.startDate = moment(this.startDate).startOf('week').format('YYYY-MM-DD');
      this.endDate = moment(this.startDate).endOf('week').format('YYYY-MM-DD');
    }

    if(this.settings.viewRange === "month"){
      this.startDate = moment(this.startDate).startOf('month').format('YYYY-MM-DD');
      this.endDate = moment(this.startDate).endOf('month').format('YYYY-MM-DD');
    }
  }

  updateDayRange(startDate,endDate){
    this.setDayRange(startDate,endDate);
    this.days = this.enumerateDays(this.startDate, this.endDate);
  }

  moveStartDate(type,startDate){
    if(!startDate){
      startDate = moment(this.startDate);
    }
    else{
      startDate = moment(startDate);
    }
    if(type === "next"){
      if(this.settings.viewRange === "week"){
        this.setDayRange(startDate.add(1,'week').startOf('week').format('YYYY-MM-DD'));
      }
      else if(this.settings.viewRange === "month"){
        this.setDayRange(startDate.add(1,'month').startOf('month').format('YYYY-MM-DD'));
      }
      else{
        this.setDayRange(startDate.add(1,'days').format('YYYY-MM-DD'));
      }
    }
    else if(type === "prev"){
      this.setDayRange(startDate.subtract(1,'days').format('YYYY-MM-DD'));
    }
    else if(type === "today"){
      this.setDayRange(moment().format('YYYY-MM-DD'));
    }
    else if(type === "this-week"){
      this.setDayRange(moment().startOf('week').format('YYYY-MM-DD'));
    }
    else if(type === "this-month"){
      this.setDayRange(moment().startOf('month').format('YYYY-MM-DD'));
    }
    else{
      //no action
    }

    this.days = this.enumerateDays(this.startDate, this.endDate);
  }

  startDateIs(type){
    if(type === "today" && this.days[0].isToday){
      return true;
    }

    if(type === "this-week"){
      return moment().isBetween(this.startDate,this.endDate);
    }

    if(type === "this-month"){
      return moment().isBetween(this.startDate,this.endDate);
    }

    return false;
  }

  setViewRange(newRange){
    this.settings.viewRange = newRange;
    this.setDayRange(this.startDate);
    this.days = this.enumerateDays(this.startDate,this.endDate);
  }

  setSettings(settings){
    return {
      locale: "en",
      hideWeekends: false,
      viewRange: settings.viewRange,
      slot: settings.slot || 60,
      startDate: moment().format('YYYY-MM-DDD'),
      endDate: null
    }
  }

  getOutputString(){
    if(this.settings.viewRange === 'month'){
      return moment(this.startDate).format('MMMM YYYY');
    }

    let startDate = moment(this.startDate).format('DD MMMM YYYY');
    if(startDate && this.endDate){
      return `${startDate} - ${moment(this.endDate).format('DD MMMM YYYY')}`;
    }

    return startDate;
  }

  isWeekday(date){
    return date.isoWeekday() !== 6 && date.isoWeekday() !== 7;
  }

  getWeekendDayName(date){
    if(date.isoWeekday() === 6){
      return 'saturday';
    }
    if(date.isoWeekday() === 7){
      return 'sunday';
    }

    return 'none';
  }

  getParsedDay(date){
    let data = {
      date: date.format('YYYY-MM-DD'),
      title: date.format('ddd'),
      subtitle: date.format('DD'),
      dateFormatted: date.format('DD/MM/YYYY'),
      isWeekday: this.isWeekday(date),
      weekendDayName: this.getWeekendDayName(date),
      weekday: date.isoWeekday(),
      isToday: date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
      tags: ["day"]
    }

    if(data.isWeekday){
      data.tags.push('weekday');
    }

    if(data.isToday){
      data.tags.push('today');
    }

    if(data.weekendDayName !== "none"){
      data.tags.push('weekend');
      data.tags.push('weekend-day-'+data.weekendDayName);
    }

    return data;
  }

  enumerateDays(startDate,endDate){
    var days = [];
    if(startDate && endDate){

      var currDate = moment(startDate).startOf('day');
      var lastDate = moment(endDate).startOf('day');

      let firstDate = currDate.clone();
      days.push(this.getParsedDay(firstDate));
      while(currDate.add(1, 'days').diff(lastDate) < 0) {
        let nextDay = currDate.clone();
        days.push(this.getParsedDay(nextDay));
      }
      let veryLastDay = lastDate.clone();
      days.push(this.getParsedDay(veryLastDay));
    }
    else{
      let singleDay = moment(startDate).startOf('day')
      days.push(this.getParsedDay(singleDay))
    }

    return days;
  }

  enumerateHours(slot){
    if(!slot){
      slot = this.settings.slot;
    }
    let firstHour = moment().startOf('day');
    let lastHour = moment().endOf('day').add(1,'minute');

    let hours = [];

    while(firstHour.add(slot, 'minutes').diff(lastHour) < 0) {
      let startTime = firstHour.clone();
      let endTime = startTime.clone().add(slot,'minutes');
      let startTimeFormat = startTime.format('HH:mm');
      let endTimeFormat = endTime.format('HH:mm');
      hours.push({
        start: startTimeFormat,
        end: endTime.format('HH:mm'),
        startTimeSlug: startTimeFormat.replace(':',''),
        endTimeSlug: endTimeFormat.replace(':',''),
        isCurrent: moment().isBetween(startTime,endTime)
      });
    }

    return [hours.pop()].concat(hours);
  }

  getHourStatus(day, hour){
    let timeDiff = moment().diff(day.date+' '+hour.start);
    let isCurrent = moment().isBetween(moment(day.date+' '+hour.start),moment(day.date+' '+hour.end));

    return {
      isPast: timeDiff > 0,
      isCurrent: isCurrent,
      isFuture: timeDiff < 0
    }
  }

  addEvent(data){
    this.events.push(data);
    this.addEvents(this.events);
  }

  addVirtualEvent(data){
    this.virtualEvents.push(data);
  }

  clearVirtualEvents(eventId){
    this.virtualEvents = Object.assign([],this.virtualEvents.filter(o => o.id !== eventId));
  }

  updateEvent(eventId, data){
    this.events = Object.assign([],this.events.map(item => {
      return item.id === eventId ? Object.assign(item, data) : item;
    }));
  }

  removeEvent(eventId){
    this.events = Object.assign([],this.events.filter(item => item.id !== eventId));
    this.clearVirtualEvents(eventId);
  }

  addEvents(events){
    this.events = Object.assign([],events);
  }

  getEvents(){
    return this.events;
  }

  getEventsInSlot(day, hour){
    let events = this.events.filter(ev => {
      return moment(ev.startDate+' '+ev.startTime+':01').isBetween(moment(day.date+' '+hour.start),moment(day.date+' '+hour.end));
    });

    events = events.concat(this.virtualEvents.filter(ev => {
      return moment(ev.startDate+' '+ev.startTime+':01').isBetween(moment(day.date+' '+hour.start),moment(day.date+' '+hour.end));
    }));

    return events;
  }

  getEventById(id){
    return this.events.find(o => o.id === id);
  }

  slotHasEvent(start){
    return this.events.find(ev => {
      return start.id !== ev.id && moment(start.date+' '+start.time+':01').isBetween(moment(ev.startDate+' '+ev.startTime),moment(ev.endDate+' '+ev.endTime));
    });
  }

}
