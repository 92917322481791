<template>
  <div v-if="widgets.options.length > 0">
    <div class="event-data-title">
      Meeting Link
    </div>
    <b-row class="mb-1">
      <b-col>
        <b-dropdown block size="sm" :text="widgets.selected ? widgets.selected.name : ''" variant="outline-primary">
          <b-dropdown-header id="dropdown-header-label-widgets">
            Your widgets
          </b-dropdown-header>
          <b-dropdown-item v-for="(item, key) in widgets.options" :key="'w'+key" :active="widgets.selected.id === item.id" @click="setWidget(item)">{{item.name}}</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col>
        <b-dropdown block size="sm" :text="selected ? selected.host : ''" variant="outline-primary">
          <b-dropdown-header id="dropdown-header-label-ours">
            Our hosts
          </b-dropdown-header>
          <b-dropdown-item v-for="(item, key) in meetingHosts.defaults" :key="'cd'+key" :active="item.active" @click="setMeetingLink(item.id,'defaults')">{{item.host}}</b-dropdown-item>
          <template v-if="meetingHosts.userDefined.length > 0">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header id="dropdown-header-label-yours">
              Your domains
            </b-dropdown-header>
            <b-dropdown-item v-for="(item, key) in meetingHosts.userDefined" :key="'ud'+key" :active="item.active" @click="setMeetingLink(item.id,'userDefined')">{{item.host}}</b-dropdown-item>
          </template>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-button :href="'https://'+meetingLink.host+meetingLink.endpoint" target="_blank" size="sm" block variant="outline-primary">
      {{meetingLink.host}}{{meetingLink.endpoint}}
    </b-button>
    <!--<b-form-input v-model="meetingLink.endpoint" disabled class="event-meeting-input" autocomplete="off"/>-->

    <div class="event-data-title mt-2">Meeting Password</div>
    <b-input-group size="sm">
      <b-form-input v-model="meetingLink.password" class="event-meeting-input" autocomplete="off"/>
      <b-input-group-append>
        <b-button variant="outline" @click="meetingLink.password = generatePassword()"><BIconArrowRepeat/></b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {BIconArrowRepeat} from 'bootstrap-vue';
export default {
  props: ['meetingId','password','host',"widgetId"],
  components: {
    BIconArrowRepeat
  },
  data(){
      return {
        selected: null,
        meetingHosts: {
          defaults: [
            {
              id: "callstr.com",
              host: "callstr.com",
              active: true
            },
            {
              id: "callstr.de",
              host: "callstr.de",
              active: false
            },
          ],
          userDefined: [

          ]
        },
        meetingLink: {
          endpoint: null,
          password: null,
          widgetId: null
        },
        widgets: {
          selected: null,
          options: []
        }
      }
  },

  methods:{
    generatePassword(){
      let length = 6;
      let characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let password = "";
      for (let i = 0; i < length; i++) {
        password += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return password;
    },
    generateMeetingId(){
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    setMeetingLink(id,type){
      let types = ['defaults','userDefined'];
      for(let i in types){
        let t = types[i];
        this.meetingHosts[t] = this.meetingHosts[t].map(ev => {
          ev.active = ev.id === id;
          return ev;
        });
      }

      this.selected = this.meetingHosts[type].find(o => o.active);

      let meetingId = this.meetingId ? this.meetingId : this.generateMeetingId();
      let meetingPassword = this.password? this.password : this.generatePassword();

      this.meetingLink = {
        host: this.selected.host,
        meetingId: meetingId,
        typeId: this.selected.id,
        endpoint: `/m/${this.widgets.selected.id}/${meetingId}`,
        password: meetingPassword,
        widgetId: this.widgets.selected.id
      }

      this.$emit('onMeetingLink',{meetingLink: this.meetingLink});
    },

    getWidgets(){
      return this.$api.get('widgets').then(res => {
        return res.data.records;
      }).catch(() => {
        console.log('cannot fetch widgets');
      });
    },

    setWidget(item){
      this.meetingLink.widgetId = item.id;
      this.widgets.selected = item;
      this.setMeetingLink(this.host,'defaults');
    }
  },

  mounted(){
    this.getWidgets().then((widgets) => {
      this.widgets = {options: widgets,selected:null};
      if(this.widgetId){
        this.setWidget(this.widgets.options.find(o => o.id === this.widgetId));
      }
      else{
        if(widgets[0]){
          this.setWidget(widgets[0]);
        }
      }

      if(this.widgets.options.length > 0){
        this.setMeetingLink(this.host,'defaults');
      }
    });
  }
};
</script>
