<template>
  <b-dropdown size="sm" variant="link" id="dropdown-appointment-bgColor" no-caret>
    <template #button-content>
      <BIconCircleFill :style="{color: selected.hex}" font-scale="2.7"/>
    </template>
    <b-dropdown-item v-for="(item,key) in bgColors" :key="'cd'+key" :active="item.active" @click="setSelected(item.hex)">
      <BIconCircleFill :style="{color: item.hex}"/> {{ item.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {BIconCircleFill} from 'bootstrap-vue';
export default {
  props: ['bg'],
  components: {
    BIconCircleFill
  },
  data(){
      return {
        selected: {
          id: 1,
          hex: "#007bff",
          name: "Blue",
          active: true
        },
        bgColors: [
          {
            id: 1,
            hex: "#007bff",
            name: "Blue",
            active: true
          },
          {
            id: 2,
            hex: "#ff6347",
            name: "Tomato",
            active: false
          },
          {
            id: 3,
            hex: "#6b8e23",
            name: "Olive",
            active: false
          },
          {
            id: 4,
            hex: "#66cd00",
            name: "Lettuce",
            active: false
          },
          {
            id: 5,
            hex: "#ffebcd",
            name: "Feta",
            active: false
          },
          {
            id: 6,
            hex: "#8b4513",
            name: "Balsamic Vinaigrette",
            active: false
          },
        ]
      }
  },

  methods:{
    setSelected(hex){
      this.selected = this.bgColors.find(o => o.hex === hex);
      this.bgColors = this.bgColors.map(ev => {
        ev.active = ev.hex === hex;
        return ev;
      });
      this.$emit('onSelected', {selected: this.selected});
    }
  },

  mounted(){
    this.setSelected(this.bg);
  }
};
</script>
