<template>
  <b-row>
    <b-col lg="4" md="12" sm="12" class="text-left">
      <b-form-datepicker
        v-model="selectedDate"
        button-only
        locale="en-US"
        start-weekday="1"
        aria-controls="example-input"
        size="sm"
        @context="onSelectedDate"
        button-variant="light"
        class="mr-1"
      ></b-form-datepicker>
      <b-button-group size="sm" class="mr-1">
        <b-button variant="light" @click="onMove('prev')"><BIconChevronLeft font-scale="0.9"/></b-button>
        <b-button variant="light" @click="onMove('next')"><BIconChevronRight  font-scale="0.9" /></b-button>
      </b-button-group>
      <b-button v-if="['1-day','4-days'].indexOf(calendar.settings.viewRange) !== -1" :variant="calendar.startDateIs('today') ? 'primary' : 'light'" @click="onMove('today')" size="sm">Today</b-button>
      <b-button v-if="calendar.settings.viewRange === 'week'" :variant="calendar.startDateIs('this-week') ? 'primary' : 'light'" @click="onMove('this-week')" size="sm">This Week</b-button>
      <b-button v-if="calendar.settings.viewRange === 'month'" :variant="calendar.startDateIs('this-month') ? 'primary' : 'light'" @click="onMove('this-month')" size="sm">This Month</b-button>
    </b-col>
    <b-col lg="4" md="12" sm="12" class="text-center">
      <h5 style="padding-top: 5px;">{{calendar.getOutputString()}}</h5>
    </b-col>
    <b-col lg="4" md="12" sm="12" class="text-right">
      <b-dropdown right id="dropdown-1" size="sm" variant="primary" :text="dropdownTitles[calendar.settings.viewRange]" class="m-md-2">
        <b-dropdown-item-button :active="calendar.settings.viewRange === '1-day'" @click="onSetViewRange('1-day')">Day</b-dropdown-item-button>
        <b-dropdown-item-button :active="calendar.settings.viewRange === 'week'" @click="onSetViewRange('week')">Week</b-dropdown-item-button>
        <!--<b-dropdown-item-button :active="calendar.settings.viewRange === 'month'" @click="onSetViewRange('month')">Month</b-dropdown-item-button>-->
        <b-dropdown-divider/>
        <b-dropdown-item-button :active="calendar.settings.viewRange === '4-days'" @click="onSetViewRange('4-days')">4 Days</b-dropdown-item-button>
        <!--
        <b-dropdown-divider/>
        <b-dropdown-item-button>Hide Weekends</b-dropdown-item-button>
      -->
      </b-dropdown>

      <b-button variant="light" size="sm" :to='{name: "crm.calendar.settings"}'><BIconGear/></b-button>
    </b-col>
  </b-row>
</template>

<script>
import {BIconChevronLeft,BIconChevronRight,BIconGear} from 'bootstrap-vue';
import moment from 'moment';
export default {
    props: ['calendar','automaticOpen'],
    components: {
      BIconChevronLeft,BIconChevronRight,BIconGear
    },
    data(){
        return {
          selectedDate: new Date(),
          dropdownTitles: {
            "1-day": "Day",
            "4-days": "4 Days",
            "week": "Week",
            "month": "Month",
          }
        }
    },

    methods:{
      onSelectedDate(ctx){
        this.calendar.updateDayRange(ctx.selectedYMD);
        this.fetchEvents();
      },
      onSetViewRange(type){
        this.calendar.setViewRange(type);
      },
      onMove(type){
        this.calendar.moveStartDate(type);
        this.fetchEvents();
      },

      fetchEvents(){
        let start = moment(this.calendar.startDate).valueOf() / 1000;
        let end = moment(this.calendar.endDate || this.calendar.startDate).valueOf() / 1000;

        this.$api.get(`crm/calendar/events`,{
          params: {start,end}
        }).then(res => {
          let {records} = res.data;
          this.calendar.addEvents(records.map(item => {
            let start = moment.unix(item.starttime);
            let end = moment.unix(item.endtime);
            return {
              id: item.id,
              title: item.title,
              startDate: start.format('YYYY-MM-DD'),
              startTime: start.format('HH:mm'),
              endDate: end.format('YYYY-MM-DD'),
              endTime: end.format('HH:mm'),
              bgColor: item.hexColour
            }
          }))
        }).catch((e) => {console.log(e,'cannot get events')});
      }
    },

    mounted(){
      if(this.automaticOpen && this.automaticOpen.time){
        this.selectedDate = moment.unix(this.automaticOpen.time).format('YYYY-MM-DD');
      }
    },
};
</script>
