<template>
    <b-modal ref="modalSlot" size="xl" id="modal-slot" @hide="onClose()" @close="onClose()" @cancel="onCancel()" @ok="onSave()" :hide-footer="isLoading" :hide-header="isLoading">
      <template v-if="!isLoading" #modal-header="{ close }">
        <DropdownEventColour ref="dropdrownEvColor" @onSelected="setEventColor" :bg="calendarEvent.bgColor"/>
        <b-form-input v-model="calendarEvent.title" placeholder="Add title (optional)" class="event-title" autocomplete="off"/>
        <b-button size="sm" variant="outline" @click="close()">
          <BIconX font-scale="2.7"/>
        </b-button>
      </template>

      <template v-if="!isLoading" #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <div class="float-left" v-if="errorMessage">
            <BIconInfoCircle variant="danger"/> <span class="text-danger">{{errorMessage}}</span>
          </div>
          <div class="float-right">
            <b-button v-if="editing" size="sm" variant="outline-danger" @click="onDelete()" class="mr-2">
              <BIconX class="mr-1"/> Delete
            </b-button>
            <b-button v-if="!editing" size="sm" variant="outline-secondary" @click="cancel()" class="mr-2">
              Cancel
            </b-button>
            <b-button :disabled="saveIsDisabled" :variant="editing ? 'success' : 'primary'" size="sm" @click="ok()">
              <BIconCalendar2Check class="mr-1"/> {{editing ? 'Update' : 'Create'}}
            </b-button>
          </div>
        </div>
      </template>

      <b-row v-if="slot && !isLoading">
        <b-col lg="6" md="12">
          <b-row>
            <b-col lg="6" md="12">
              <div class="mb-1">
                <div style="width: 50px; display: inline-block;">From:</div>
                <b-form-datepicker
                  v-model="calendarEvent.startDate"
                  button-only
                  locale="en-US"
                  start-weekday="1"
                  aria-controls="startDate-input"
                  size="sm"
                  @context="onSelectedStartDate"
                  button-variant="light"
                  class="mr-1"
                  :min="minStartDate"
                ></b-form-datepicker>
                {{calendarEvent.startDateFormatted}}

                <b-form-timepicker
                  size="sm"
                  v-model="calendarEvent.hmStart"
                  button-only
                  left
                  locale="de"
                  aria-controls="hmStart-input"
                  button-variant="light"
                  @context="onSelectedHMStart"
                  minutes-step="15"
                  hide-header
                  no-close-button
                ></b-form-timepicker>
                {{calendarEvent.hmStartFormatted}}
              </div>

              <div class="mb-3">
                <div style="width: 50px; display: inline-block;">To:</div>
                <b-form-datepicker
                  v-model="calendarEvent.endDate"
                  button-only
                  locale="en-US"
                  start-weekday="1"
                  aria-controls="endDate-input"
                  size="sm"
                  @context="onSelectedEndDate"
                  button-variant="light"
                  class="mr-1"
                  :min="calendarEvent.startDate"
                ></b-form-datepicker>
                {{calendarEvent.endDateFormatted}}
                <b-form-timepicker
                  size="sm"
                  v-model="calendarEvent.hmEnd"
                  button-only
                  left
                  locale="de"
                  aria-controls="hmEnd-input"
                  button-variant="light"
                  minutes-step="15"
                  hide-header
                  @context="onSelectedHMEnd"
                  no-close-button
                ></b-form-timepicker>
                {{calendarEvent.hmEndFormatted}}
              </div>
            </b-col>
            <b-col lg="6" md="12">

            </b-col>
          </b-row>

          <b-form-textarea v-model="calendarEvent.message" placeholder="Add message (optional)" class="event-message mb-3" rows="6" no-resize/>
        </b-col>
        <b-col lg="6" md="12">
          <template v-if="canChooseSettings">
            <div class="event-data">
              <div class="event-data-title">Appointment Type</div>
              <DropdownAppointmentTypes :selectedId="selectedAppointmentType" @onSelected="setSelectedAppointmentType"/>
            </div>

            <div v-if="calendarEvent.eventType && calendarEvent.eventType.id === onlineMeetingId" class="event-data">
              <MeetingLink :host="calendarEvent.meetingLink.host || 'callstr.com'" :meetingId="calendarEvent.meetingLink.meetingId" :password="calendarEvent.meetingLink.password" :widgetId="calendarEvent.meetingLink.widgetId" @onMeetingLink="setMeetingLink"/>
            </div>

            <div v-if="calendarEvent.eventType && calendarEvent.eventType.id === onlineMeetingId" class="event-data">
              <div class="event-data-title">Guest</div>
              <b-form-input size="sm" v-model="calendarEvent.guestEmail" @keyup="validateEvent()" class="event-meeting-input" placeholder="Add email address" autocomplete="off"/>
            </div>
          </template>
          <div v-else>
            Please select a valid date and time range
          </div>
        </b-col>
      </b-row>

      <div v-if="isLoading" class="p-1">
        <div class="float-left">Please wait...</div>
        <b-spinner class="float-right" label="Loading..." small></b-spinner>
      </div>
    </b-modal>
</template>

<script>
import moment from 'moment';
import {BIconCalendar2Check,BIconX,BIconInfoCircle} from 'bootstrap-vue';
import DropdownAppointmentTypes from './../components/Dropdown.Appointment.Types';
import MeetingLink from './../components/Meeting.Link';
import DropdownEventColour from './../components/Dropdown.Event.Colour';
export default {
    components: {
      BIconCalendar2Check,BIconX,BIconInfoCircle,
      DropdownAppointmentTypes,MeetingLink,DropdownEventColour
    },

    data(){
      return Object.assign({},this.initialState());
    },

    methods:{
      onDelete(){
        //console.log("on delete")
        this.deleteEvent().then(() => {
          this.calendar.removeEvent(this.calendarEvent.id);
          Object.assign({},this.$data, this.initialState());
          this.$refs['modalSlot'].hide();
        })
      },
      onClose(){
        //console.log("on close")
        //Object.assign(this.$data, this.initialState());
      },
      onCancel(){
        //console.log("on cancel")
        Object.assign({},this.$data, this.initialState());
      },
      onSave(){
        let data = {
          title: this.calendarEvent.title,
          message: this.calendarEvent.message,
          meetingLink: this.calendarEvent.meetingLink,
          startDate: this.calendarEvent.startDate,
          startTime: this.calendarEvent.hmStartFormatted,
          endDate: this.calendarEvent.endDate,
          endTime: this.calendarEvent.hmEndFormatted,
          bgColor: this.calendarEvent.bgColor,
          eventType: this.calendarEvent.eventType,
          guestEmail: this.calendarEvent.guestEmail,
        }
        if(this.editing){
          this.calendar.clearVirtualEvents(this.calendarEvent.id);
          this.calendar.updateEvent(this.calendarEvent.id,data);
          this.$emit('onEventUpdated',{eventId: this.calendarEvent.id});
          this.updateEvent().then(() => {
            Object.assign({},this.$data, this.initialState());
          });
        }
        else{
          this.makeEvent().then(ev => {
            this.isBlocked = false;
            if(ev){
              this.calendar.addEvent(Object.assign(data,ev));
            }
            Object.assign({},this.$data, this.initialState());
          });
        }
      },
      setSelectedAppointmentType({selected}){
        this.calendarEvent.eventType = selected;
        this.calendarEvent = Object.assign({},this.calendarEvent);
        this.validateEvent();
      },
      setEventColor({selected}){
        this.calendarEvent.bgColor = selected.hex;
        this.calendarEvent = Object.assign({},this.calendarEvent);
      },

      isValidEmail(){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.calendarEvent.guestEmail).toLowerCase());
      },

      setMeetingLink({meetingLink}){
        this.calendarEvent.meetingLink = meetingLink;
        this.calendarEvent = Object.assign({},this.calendarEvent);
      },
      onSelectedStartDate(ctx){
        let endDate = this.calendarEvent.endDate;
        if(moment(ctx.selectedYMD) > moment(endDate)){
          endDate = ctx.selectedYMD;
        }
        this.setDates(ctx.selectedYMD, endDate);
        this.validateEvent();
      },
      onSelectedEndDate(ctx){
        this.setDates(this.calendarEvent.startDate,ctx.selectedYMD);
        this.validateEvent();
      },
      onSelectedHMStart(ctx){
        this.setTimes(ctx.value, this.calendarEvent.hmEnd);
        this.validateEvent();
      },
      onSelectedHMEnd(ctx){
        this.setTimes(this.calendarEvent.hmStart, ctx.value);
        this.validateEvent();
      },
      formatDate(string){
        return moment(string).format('DD/MM/YYYY');
      },
      formatTime(string){
        return string.substr(0,5);
      },
      setDates(startDate,endDate){
        this.calendarEvent.startDate = startDate;
        this.calendarEvent.endDate = endDate;

        this.calendarEvent.startDateFormatted = this.formatDate(this.calendarEvent.startDate);
        this.calendarEvent.endDateFormatted = this.formatDate(this.calendarEvent.endDate);
        this.calendarEvent = Object.assign({},this.calendarEvent);
      },
      setTimes(startTime,endTime){
        this.calendarEvent.hmStart = startTime;
        this.calendarEvent.hmEnd = endTime;

        this.calendarEvent.hmStartFormatted = this.formatTime(this.calendarEvent.hmStart);
        this.calendarEvent.hmEndFormatted = this.formatTime(this.calendarEvent.hmEnd);
        this.calendarEvent = Object.assign({},this.calendarEvent);
      },
      validateEvent(){
        let errorMessage = null;
        let saveIsDisabled = false;
        let canChooseSettings = true;

        let startDT = this.calendarEvent.startDate+' '+this.calendarEvent.hmStart;
        let endDT = this.calendarEvent.endDate+' '+this.calendarEvent.hmEnd;
        if(startDT.toString() === endDT.toString()){
          saveIsDisabled = true;
          canChooseSettings = false;
          errorMessage = "The start date and time cannot be the same with end date and time!";
        }

        if(moment(startDT) > moment(endDT)){
          saveIsDisabled = true;
          canChooseSettings = false;
          errorMessage = "The end date and time cannot be smaller than start date and time!";
        }

        if(moment(this.calendarEvent.startDate+' '+this.calendarEvent.hmStart) < moment()){
          if(!this.editing){
            saveIsDisabled = true;
            canChooseSettings = false;
            errorMessage = "You cannot set an appointment in the past!";
          }
        }

        if(this.calendarEvent.guestEmail !== null && this.calendarEvent.guestEmail !== ""){
          if(!this.isValidEmail()){
            saveIsDisabled = true;
            errorMessage = "Please enter a valid email address!";
          }
        }
        else{
          if(this.calendarEvent.eventType && this.calendarEvent.eventType.id === this.onlineMeetingId){
            saveIsDisabled = true;
            errorMessage = "Please enter an email address!";
          }
        }

        if(this.calendar.slotHasEvent({
          id: this.calendarEvent.id,
          date: this.calendarEvent.startDate,
          time: this.calendarEvent.hmStartFormatted
        })){
          this.slotIsEmpty = false;
          saveIsDisabled = true;
          canChooseSettings = false;
          errorMessage = "This slot is already occupied!";
        }
        else{
          this.slotIsEmpty = true;
        }

        this.saveIsDisabled = saveIsDisabled;
        this.errorMessage = errorMessage;
        this.canChooseSettings = canChooseSettings;
      },
      initialState(){
        return {
          isLoading: false,
          isBlocked: false,
          canChooseSettings: false,
          slotIsEmpty: false,
          selectedAppointmentType: null,
          onlineMeetingId: "ca303fc3d28844b4688b",
          calendar: null,
          slot: null,
          editing: false,
          calendarEvent: {
            id: null,
            title: null,
            message: null,
            meetingLink: {
              meetingId: null,
              typeId: null,
              host: null,
              endpoint: null,
              password: null,
              widgetId: null
            },
            guestEmail: null,
            startDate: null,
            endDate: null,
            startDateFormatted: null,
            endDateFormatted: null,
            hmStart: null,
            hmEnd: null,
            hmStartFormatted: null,
            hmEndFormatted: null,
            eventType: null,
            bgColor: "#007bff"
          },
          minStartDate: new Date(),
          saveIsDisabled: true,
          errorMessage: null,
        }

      },

      setEventData(ev){
        return Object.assign(this.calendarEvent,{
          id: ev.id,
          title: ev.title,
          message: ev.content,
          guestEmail: ev.recipients.length > 0 ? ev.recipients[0].email : null,
          meetingLink: {
            meetingId: ev.id,
            typeId: null,
            host: ev.otherData.meetingHost,
            password: ev.otherData.password,
            widgetId: ev.otherData.widgetId,
            //endpoint: `/m/${ev.id}`
            endpoint: null
          },
          bgColor: ev.hexColour,
        });
      },

      getEventData(){
        //let status = this.editing ? 'active' : 'in_progress';
        return {
          title: this.calendarEvent.title,
          content: this.calendarEvent.message,
          eventTypeId: this.calendarEvent.eventType.id,
          starttime: moment(this.calendarEvent.startDate +' '+this.calendarEvent.hmStart).valueOf() / 1000,
          endtime: moment(this.calendarEvent.endDate +' '+this.calendarEvent.hmEnd).valueOf() / 1000,
          status: "active",
          hexColour: this.calendarEvent.bgColor,
          otherData: {
            meetingHost: this.calendarEvent.meetingLink.host,
            password: this.calendarEvent.meetingLink.password,
            widgetId: this.calendarEvent.meetingLink.widgetId,
          },
          recipients: [
            {
              name: null,
              email: this.calendarEvent.guestEmail
            }
          ]
        };
      },

      mount(slot, calendar){
        this.selectedAppointmentType = this.onlineMeetingId;
        this.calendar = calendar;
        slot.hour.status = this.calendar.getHourStatus(slot.day,slot.hour);
        this.slot = slot;

        if(slot.item){
          this.editing = true;
          this.isLoading = true;
          this.fetchEvent(slot.item.id).then(ev => {
            this.selectedAppointmentType = ev.eventTypeId;

            this.calendarEvent = Object.assign({},this.setEventData(ev));
            if(this.$refs['dropdrownEvColor']){
              this.$refs['dropdrownEvColor'].setSelected(this.calendarEvent.bgColor);
            }

            this.setDates(slot.item.startDate,slot.item.endDate);
            this.setTimes(slot.item.startTime,moment(slot.item.endDate+' '+slot.item.endTime).format('HH:mm'));
            this.isLoading = false;
          });

          this.setDates(slot.item.startDate,slot.item.endDate);
          this.setTimes(slot.item.startTime,moment(slot.item.endDate+' '+slot.item.endTime).format('HH:mm'));
        }
        else{
          this.editing = false;

          this.calendarEvent = {
            id: null,
            title: null,
            message: null,
            meetingLink: {
              meetingId: null,
              typeId: null,
              host: null,
              endpoint: null,
              password: null,
              widgetId: null
            },
            guestEmail: null,
            startDate: null,
            endDate: null,
            startDateFormatted: null,
            endDateFormatted: null,
            hmStart: null,
            hmEnd: null,
            hmStartFormatted: null,
            hmEndFormatted: null,
            eventType: null,
            bgColor: "#007bff"
          };

          this.setDates(this.slot.day.date,this.slot.day.date);
          this.setTimes(this.slot.hour.start,moment(this.slot.day.date+' '+this.slot.hour.start).add(15,'minutes').format('HH:mm'));
        }
        this.validateEvent();
        this.$refs['modalSlot'].show();
      },

      fetchEvent(eventId){
        return this.$api.get(`crm/calendar/event/${eventId}`).then(res => {
          return res.data;
        }).catch((e) => {console.log(e,'cannot fetch event')});
      },

      deleteEvent(){
        return this.$api.delete(`crm/calendar/event/${this.calendarEvent.id}`)
        .catch((e) => {console.log(e,'cannot delete event')});
      },

      makeEvent(){
        return this.$api.post(`crm/calendar/event`,{
          data: this.getEventData()
        })
        .then(res => {
          this.calendarEvent = Object.assign({},this.setEventData(res.data));
          return this.calendarEvent;
        })
        .catch((e) => {console.log(e,'cannot create event')});
      },

      updateEvent(){
        return this.$api.put(`crm/calendar/event/${this.calendarEvent.id}`,{
          data: this.getEventData()
        })
        .catch((e) => {console.log(e,'cannot update event')});
      }
    },

    mounted(){
    },

    /*
    watch: {
      "calendarEvent": function(ev){
        if(this.editing === true || this.isBlocked === true){
          return;
        }

        let check = ['bgColor','startDate','endDate','hmStart','hmEnd','meetingLink','eventType'];
        let total = 0;
        for(let i in check){
          if(ev[check[i]] !== null){
            total++;
          }
        }

        if(total === check.length && this.slotIsEmpty){
          console.log("create block event");
          this.isBlocked = true;
          this.makeEvent();
        }
      }
    }
    */
};
</script>
