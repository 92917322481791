<template>
    <div v-if="calendar" id="calendar">
      <SlotModal ref="slotModal" @onEventUpdated="onEventUpdated"/>
      <div class="calendar-head">
        <Head :calendar="calendar" :automaticOpen="automaticOpen"/>
      </div>

      <div class="calendar-body">
        <BodyDaysView ref="calendarView" :calendar="calendar" @onSlotSelected="onSlotSelected" @onEditEvent="onEditEvent" :automaticOpen="automaticOpen"/>
      </div>
    </div>
</template>

<script>
import './style.css';
import Calendar from './Calendar';
import Head from './calendar/Head';
import BodyDaysView from './calendar/Body.Days.View';
import SlotModal from './calendar/Slot.Modal';
export default {
    props: [],
    components: {
      Head,
      BodyDaysView,
      SlotModal
    },
    data(){
        return {
          calendar: null,
          slot: null,
          automaticOpen: null
        }
    },

    methods:{
      onSlotSelected(slot){
        this.slot = slot;

        this.$refs['slotModal'].mount(slot, this.calendar);
      },

      onEditEvent(data){
        data.item = this.calendar.getEventById(data.item.id);
        this.$refs['slotModal'].mount(data, this.calendar);
      },

      onEventUpdated(data){
        this.$refs['calendarView'].updateSlotEvent(data.eventId);
      },
      openDetails(){
        let {id,time,view} = this.$route.query || {};
        if(id && time && view === true){
          this.automaticOpen = {id,time,view};
        }
      }
    },

    mounted(){
      this.calendar = new Calendar({
        locale: "en",
        viewRange: "week",
        slot: 60
      });

      this.openDetails();
    },
};
</script>
